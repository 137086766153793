import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
// const cors = require('cors');
const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console.log("Message submited: " + JSON.stringify(data));

    // console.log(JSON.stringify(this.state.data));
    const correctData = {};

    correctData.name = data.name;
    correctData.email = data.email;
    correctData.phone = data.phone;
    correctData.subject = data.subject;
    correctData.content = data.message;
    axios.post(
        "https://ce1uxmyiue.execute-api.us-east-2.amazonaws.com/v1", {
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        correctData}
      
      )
      .then(
        (response) => {
          console.log("Response: " + JSON.stringify(response));
        },
        (error) => {
          console.log("Error " + error);
        }
      );





  };

  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first_row">
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Name *"
          />
          {errors.name && errors.name.type === "required" && (
            <span className="invalid-feedback">Name is required</span>
          )}
        </div>
        {/* End .first_row */}

        <div className="second">
          <div className="left">
            <input
              {...register(
                "email",
                {
                  required: "Email is Required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                },
                { required: true }
              )}
              type="email"
              placeholder="Email *"
            />
            {errors.email && (
              <span className="invalid-feedback">{errors.email.message}</span>
            )}
          </div>
          <div className="right">
            <input
              {...register("subject", { required: true })}
              placeholder="Subject *"
            />
            {errors.subject && (
              <span className="invalid-feedback">Subject is required.</span>
            )}
          </div>
        </div>
        {/* End .second */}

        <div className="third">
          <textarea
            {...register("message", { required: true })}
            placeholder="Message *"
          ></textarea>
          {errors.message && (
            <span className="invalid-feedback">Message is required.</span>
          )}
        </div>
        {/* End .third */}

        <div className="beny_tm_button">
          <button type="submit" className="color">
            <span className="wrapper">
              <span className="first">Submit</span>
              <span className="second">Submit</span>
            </span>
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
